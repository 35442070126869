import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "session-iv",
      "style": {
        "position": "relative"
      }
    }}>{`Session IV`}<a parentName="h1" {...{
        "href": "#session-iv",
        "aria-label": "session iv permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <h2 {...{
      "id": "ever-deeper-dimensions",
      "style": {
        "position": "relative"
      }
    }}>{`Ever Deeper Dimensions`}<a parentName="h2" {...{
        "href": "#ever-deeper-dimensions",
        "aria-label": "ever deeper dimensions permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <h3 {...{
      "id": "objective",
      "style": {
        "position": "relative"
      }
    }}>{`Objective`}<a parentName="h3" {...{
        "href": "#objective",
        "aria-label": "objective permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Dive deeper into the Zodiac open standard and build your own Zodiac mod.`}</p>
    <h3 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}>{`Preparation`}<a parentName="h3" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <ol>
      <li parentName="ol">{`Follow the tutorial: `}<a parentName="li" {...{
          "href": "https://gnosis.github.io/zodiac/docs/tutorial-build-a-module/setup"
        }}>{`https://gnosis.github.io/zodiac/docs/tutorial-build-a-module/setup`}</a>{`.`}</li>
    </ol>
    <h3 {...{
      "id": "application",
      "style": {
        "position": "relative"
      }
    }}>{`Application`}<a parentName="h3" {...{
        "href": "#application",
        "aria-label": "application permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <ul>
      <li parentName="ul">{`Zodiac: The open standard (5 minutes plus 5 minute Q&A)`}
        <ul parentName="li">
          <li parentName="ul">{`Revisit the Zodiac open standard architecture`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Zodiac Build (50 minutes plus 5 minute Q&A)`}
        <ul parentName="li">
          <li parentName="ul">{`Guided `}<a parentName="li" {...{
              "href": "https://gnosis.github.io/zodiac/docs/tutorial-build-a-module/setup"
            }}>{`tutorial`}</a>{` on building your own Zodiac mod`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "recording",
      "style": {
        "position": "relative"
      }
    }}>{`Recording`}<a parentName="h3" {...{
        "href": "#recording",
        "aria-label": "recording permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <Video src="https://www.youtube-nocookie.com/embed/7L2RqXxuPHI" mdxType="Video" />
    <p><img parentName="p" {...{
        "src": "/images/build/zodiac_songline.png",
        "alt": "Zodiac Songline for Progressive Decentralization"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      